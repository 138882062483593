import $ from "jquery";

import "./style.scss";

$(".header__nav__button").on("click", () => $("body").toggleClass("menu-open"));

$(".header__nav > ul > li .current-menu-item")
  .parent()
  .closest("li")
  .addClass("current-menu-item-parent");
